import React, {Component} from "react";
import {Box, Heading} from 'grommet';
import {GroupableTable} from "./content";
import {ClinicalDetails} from "./details/description";
import {ClinicalTrial} from "../icons";


export class ClinicalTrials extends Component {

    render() {
        let url = require(".././data/url");
        const {data} = this.props;

        return (

            <Box fill align="start" justify="center">
                <Heading>Clinical Trials</Heading>


                <GroupableTable

                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    columns={
                        [
                            {title: 'Title', field: 'title'},
                            {title: 'Status', field: "status"},
                            {title: 'Phase', field: 'phases'},
                            {title: 'Start Date', field: 'start_date', defaultSort: "desc"},
                            {title: 'Completion Date', field: 'completion_date'},
                            {title: 'Result', field: 'result'},

                        ]
                    }

                    detailPanel={[
                        {

                            icon: ClinicalTrial,
                            tooltip: 'Details',
                            render: rowData => {


                                return (
                                    <ClinicalDetails heading={rowData.title}
                                                     url={url.clinicalDetails + rowData.id}
                                                     nct={rowData.nct}
                                    />

                                )
                            }
                        },

                        /* {

                             icon :Drug,
                             tooltip: 'Drug',
                             render: rowData => {


                                 return (
                                     <MoleculeDetails heading={rowData.name}
                                                      url={url.clinicalDetails + rowData.id}
                                                      nct={rowData.nct}
                                     />

                                 )
                             }
                         },*/


                    ]}


                    data={data}
                />


            </Box>


        );
    }
}