import React, {Component } from "react";
import {Box, Heading} from "grommet";
import "./Details.css"
import LinkedElement from "./LinkedElement";
import Element from "./Element";




export class ClinicalDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clinicalData: [],
        };
    }

    componentDidMount() {
        const {url} = this.props;
        fetch(url)
            .then(response => response.json())
            .then(clinicalData => this.setState({clinicalData: clinicalData}));

    }


    render() {
        const {heading,nct} = this.props;

        const {clinicalData} = this.state;


        console.log(nct);

        return (

            <Box fill align="start" justify="center" background={"#fafafa"} pad="small">
                <Heading
                    color={"#76B7B2"} level={2}>
                    {heading}</Heading>

                <LinkedElement
                name={"ClinicalTrials.gov Identifier"}
                id={nct}
                link={"https://clinicaltrials.gov/ct2/show/"}
            />


                <Element
                    name={"Interventions"}
                    id={clinicalData["interventions"]}

                />
                <Element
                name={"Measures"}
                id={clinicalData["measures"]}

            />
                <Element
                    name={"Gender"}
                    id={clinicalData["gender"]}

                />
                <Element
                    name={"Age"}
                    id={clinicalData["age"]}

                />
                <Element
                    name={"Designs"}
                    id={clinicalData["designs"]}

                />
                <Element
                    name={"Primary completion date"}
                    id={clinicalData["primary_completion_date"]}

                />
                <Element
                    name={"First posted date"}
                    id={clinicalData["first_posted_date"]}

                />


               {/* <DetailTable
                    data={clinicalData}


                    columns={
                        [  {title: 'Interventions', field: 'interventions'},
                            {title: 'measures', field: "measures"},
                            {title: 'gender', field: "gender"},
                            {title: 'age', field: "age"},
                            {title: 'designs', field: "designs"},
                            {title: 'primary_completion_date', field: "primary_completion_date"},
                            {title: 'first_posted_date', field: "first_posted_date"},

                        ]
                    }

                />
*/}

            </Box>

        );
    }
}
