import React from "react";
import {Box, Heading, Text} from "grommet";
import {Drug, Interaction, Publication, Target} from "../icons";


const size = 20;
const brand = "#76B7B2";

export const Submit = () => (
    <Box fill align="start">
        <Heading>Submit</Heading>
        <Text style={{fontSize: size}}>
            If you like to contribute your publication and data to <Text
            style={{fontSize: size, color: brand, fontWeight: "bold"}}>CORDITE </Text> please send an E-Mail
            to: {"\n"}{"\n"}
        </Text>


        <Box className="List" style={{fontSize: size, paddingTop: 20, paddingLeft: 80, paddingBottom: 20}}>
            <Text style={{fontSize: size, color: brand}}> dominik.heider@uni-marburg.de</Text>
        </Box>


        <Text style={{fontSize: size}}>
            providing the following information:</Text>


        <Box className="List" style={{fontSize: size, paddingTop: 20, paddingLeft: 80, paddingBottom: 20}}>
            

            <Text style={{fontSize: size}}> <Publication/>Publication:</Text>
            <Text className={"ListItem"} style={{paddingBottom: 20}}>

                Where are the results published?
            </Text>

            <Text style={{fontSize: size}}> <Drug/> Drugs:</Text>
            <Text className={"ListItem"} style={{paddingBottom: 20}}>

                Which drugs are involved?
            </Text>
            <Text style={{fontSize: size}}> <Target/> Targets:</Text>
            <Text className={"ListItem"} style={{paddingBottom: 20}}>

                Which targets are involved?
            </Text>

            <Text style={{fontSize: size}}> <Interaction/> Interactions:</Text>
            <Text className={"ListItem"} style={{paddingBottom: 20}}>

                Which drug belongs to which target?

            </Text>

        </Box>

    </Box>
);
