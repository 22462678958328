import React, {Component} from "react";
import {Publication, Drug, Target, Interaction,ClinicalTrial} from "../icons";
import corona from "../images/corona3.svg";
import "./About.css"
import {Box, Text} from "grommet";


const size = 20;
const brand = "#76B7B2";


export class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intData: [],
        };
    }

    componentDidMount() {
        let url = require("../data/url");
        //const {url} = this.props;
        fetch(url.stats)
            .then(response => response.json())
            .then(intData => this.setState({intData: intData}));

    }


    render() {
        const {intData} = this.state;
        return (

            <Box>

                <span>&nbsp;&nbsp;</span>


                <Box style={{flexDirection: 'row', padding: 20}}>


                    <Text style={{fontSize: 75, lineHeight: -20, fontWeight: "bold", color: brand}}>
                        C
                    </Text>

                    <img src={corona} className="App-logo2" alt=""/>

                    <Text style={{fontSize: 75, lineHeight: -20, fontWeight: "bold", color: brand}}>
                        RDITE
                    </Text>


                </Box>
                <Text>
                    Last updated: {intData["last_publication_update"]}</Text>
                <Box align={"center"} style={{padding: 20}}>
                    <video
                        width="560"
                        height="315"
                        controls

                        src="https://corona.mathematik.uni-marburg.de/backend/cordite.mp4"
                    >
                        Your browser does not support the video tag.
                    </video>


                </Box>


                <Text style={{fontSize: size}}>
                    CORDITE (CORona Drug InTEractions database) collects and aggregates data for SARS-CoV-2 from:{"\n"}
                    {"\n"}
                </Text>
                <Box className="List" style={{fontSize: size, paddingTop: 20, paddingLeft: 80, paddingBottom: 20}}>

                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://www.ncbi.nlm.nih.gov/pubmed/"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Publication/> PubMed</a>
                        {"\n"} </Text>
                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://www.medrxiv.org/"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Publication/> MedRxiv</a>
                        {"\n"} </Text>
                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://www.biorxiv.org/"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Publication/> BioRxiv</a>
                        {"\n"} </Text>
                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://chemrxiv.org/"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Publication/> ChemRxiv</a>
                        {"\n"} </Text>
                    <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://clinicaltrials.gov/"
                                                                           rel="noopener noreferrer"
                                                                           target="_blank"><Publication/> ClinicalTrials.gov</a>
                        {"\n"} </Text>
                    {"\n"}

                </Box>
                <Text className="Text" style={{fontSize: size}}>
                    Its main focus is set on drug interactions either addressing viral proteins or human proteins that
                    could be used to treat COVID.
                    It collects and provides up-to-date information on computational predictions, in vitro, in vivo
                    study data as well as clinical trials:{"\n"}{"\n"}

                    <Box className="List" style={{fontSize: size, paddingTop: 20, paddingLeft: 80, paddingBottom: 20}}>

                        <Text style={{fontSize: size, color: brand}}>{"\t"} <a
                            href={"#/interactions"}><Interaction/> Interactions: </a> {intData["interactions"]}
                            {"\n"} </Text>

                        <Text style={{fontSize: size, color: brand}}>{"\t"} <a href={"#/targets"}><Target/> Targets:
                        </a> {intData["targets"]}
                            {"\n"} </Text>

                        <Text style={{fontSize: size, color: brand}}>{"\t"} <a href={"#/drugs"}><Drug/> Drugs:
                        </a> {intData["drugs"]}
                            {"\n"} </Text>

                        <Text style={{fontSize: size, color: brand}}>{"\t"} <a
                            href={"#/publications"}><Publication/> Publications: </a> {intData["publications"]}
                            {"\n"} </Text>
                        <Text style={{fontSize: size, color: brand}}>{"\t"} <a
                            href={"#/clinicaltrials"}><ClinicalTrial/> Clinical Trials:
                        </a> {intData["clinical_trials"]}
                            {"\n"} </Text>
                    </Box>

                    {"\n"}
                    The information provided is for research only and we cannot guarantee the correctness of the
                    data.{"\n"}
                    {"\n"}
                    Please contact<Text style={{fontSize: size, color: brand}}> dominik.heider@uni-marburg.de </Text>for
                    further information.{"\n"}
                    {"\n"}


                </Text>
                <span>&nbsp;&nbsp;</span>
                <span>&nbsp;&nbsp;</span>
                <Box>
                    Please cite: <a href="https://www.cell.com/iscience/fulltext/S2589-0042(20)30484-3"
                                    rel="noopener noreferrer"
                                    target="_blank">  Martin R, Löchel HF, Welzel M, Hattab G, Hauschild AC, Heider D: CORDITE: the curated CORona Drug InTERactions database for SARS-CoV-2. Cell iScience 2020</a>


                </Box>

                <span>&nbsp;&nbsp;</span>

            </Box>


            /*           <span>&nbsp;&nbsp;</span>


                 <View style={{flexDirection: 'row'}}>

                     <Text style={{fontSize: size}}>
                         {"\n"}
                     </Text>

                     <Text style={{fontSize: 50, lineHeight: 15, fontWeight: "bold", color: brand}}>
                         C
                     </Text>

                     <img src={corona} className="App-logo2" alt=""/>

                     <Text style={{fontSize: 50, lineHeight: 15, fontWeight: "bold", color: brand}}>
                         RDITE
                     </Text>

                 </View>


                 <Text style={{fontSize: size}}>
                     CORDITE (CORona Drug InTEractions database) collects and aggregates data for SARS-CoV-2 from:{"\n"}
                     {"\n"}
                 </Text>
                 <Text style={{fontSize: size}}>

                     <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://www.ncbi.nlm.nih.gov/pubmed/"
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"><Publication/> PubMed</a>
                         {"\n"} </Text>
                     <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://www.medrxiv.org/"
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"><Publication/> MedRxiv</a>
                         {"\n"} </Text>
                     <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://www.biorxiv.org/"
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"><Publication/> BioRxiv</a>
                         {"\n"} </Text>
                     <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://chemrxiv.org/"
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"><Publication/> ChemRxiv</a>
                         {"\n"} </Text>
                     <Text style={{fontSize: size, color: brand}}>{"\t"} <a href="https://clinicaltrials.gov/"
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"><Publication/> ClinicalTrails.gov</a>
                         {"\n"} </Text>
                     {"\n"}

                 </Text>
                 <Text className="Text" style={{fontSize: size}}>
                     Its main focus is set on drug interactions either addressing viral proteins or human proteins that
                     could be used to treat COVID.
                     It collects and provides up-to-date information on computational predictions, in vitro, in vivo
                     study data as well as clinical trails:{"\n"}{"\n"}

                     <Text style={{fontSize: size, color: brand}}>{"\t"} <a
                         href={"/interactions"}><Interaction/> Interactions: </a> {intData["interactions"]}
                         {"\n"} </Text>

                     <Text style={{fontSize: size, color: brand}}>{"\t"} <a href={"/targets"}><Target/> Targets:
                     </a> {intData["targets"]}
                         {"\n"} </Text>

                     <Text style={{fontSize: size, color: brand}}>{"\t"} <a href={"/drugs"}><Drug/> Drugs:
                     </a> {intData["drugs"]}
                         {"\n"} </Text>

                     <Text style={{fontSize: size, color: brand}}>{"\t"} <a
                         href={"/publications"}><Publication/> Publications: </a> {intData["publications"]}
                         {"\n"} </Text>
                     <Text style={{fontSize: size, color: brand}}>{"\t"} <a
                         href={"/clinicaltrails"}><ClinicalTrail/> Clinical Trails: </a> {intData["clinicaltrails"]}
                         {"\n"} </Text>

                     {"\n"}
                     The information provided is for research only and we cannot guarantee the correctness of the
                     data.{"\n"}
                     {"\n"}
                     Please contact<Text style={{fontSize: size, color: brand}}> dominik.heider@uni-marburg.de </Text>for
                     further information.{"\n"}
                     {"\n"}
                 </Text>*/
        );
    }
}
