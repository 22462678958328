import React, {Component} from "react";
import {Box} from 'grommet';
import {GroupableTable} from "../../content";
import {ResearchDetails} from "./index";
import {Publication} from "../../../icons";



export class OtherPublications extends Component {

    render() {

        let url = require("../../../data/url");
        const {data} = this.props;

        return (

            <Box fill align="start" justify="center" animation={"slideLeft"}>
                <GroupableTable

                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    columns={
                        [
                            {title: 'Name', field: 'name', defaultSort: "asc"},
                            {title: 'Authors', field: "author"},
                            {title: 'Source', field: 'database'},
                            /* {title: 'DOI', field: 'doi'},

                             {title: 'Curated', field: 'curated', lookup: {1: 'yes'}},*/
                            {title: 'Date', field: 'created'},
                        ]
                    }

                    detailPanel={[
                        {

                            icon :Publication,
                            tooltip: 'Details',
                            render: rowData => {


                                return (
                                    <ResearchDetails heading={rowData.name}
                                                     url={url.pubDetails + rowData.id}
                                                     doi={rowData.doi}
                                    />

                                )
                            }
                        },

                    ]}


                    data={data}
                />


            </Box>


        );
    }
}