import React, {Component} from "react";
import {Box, Heading} from "grommet";
import {DetailTable} from "../../content";
import LinkedElement from "../description/LinkedElement";
import Element from "../description/Element";
import {LoadingBar} from "../../../LoadingBar";


export class MoleculesTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intData: [],
            loading: true,
        };
    }

    componentDidMount() {
        const {url} = this.props;
        fetch(url)
            .then(response => response.json())
            .then(intData => this.setState({intData: intData["molecules"],
                loading:false }))

    }


    render() {
        const {intData,loading} = this.state;
        const {heading} = this.props;
        return (

            <Box fill align="start" justify="center" background={"#fafafa"} pad="small">
                <Box fill align="start" justify="center">
                    <Heading
                        color={"#76B7B2"} level={2}>
                        {heading}
                    </Heading>

                </Box>
                <Box fill align="center" justify="center">

                    {loading ?      <LoadingBar/> :
                    <DetailTable
                        onRowClick={(event, rowData, togglePanel) => togglePanel()}

                        data={intData}


                        columns={
                            [
                                {title: 'Name', field: 'name', defaultSort: "asc"},
                                {title: 'Tags', field: 'tags'},
                                {title: 'Synonym', field: 'syn'},
                            ]
                        } detailPanel={[
                        {
                            tooltip: 'Details',
                            render: rowData => {
                                return (
                                    <Box fill align="start" justify="center" background={"#fafafa"} pad="small">
                                        <Heading
                                            color={"#76B7B2"} level={2}>
                                            {heading}</Heading>

                                        <h2>
                                            Tag: {rowData.tags}
                                        </h2>

                                        {/*    <Element
                name={"Gene"}
                id={drugData.gene}
                />*/}

                                        <Element
                                            name={"Smile"}
                                            id={rowData.smile}
                                        />

                                        <Element
                                            name={"Description"}
                                            id={rowData.description}
                                        />

                                        {/*              <Element
                    name={"Specie"}
                    id={drugData.specie}
                />*/}

                                        <LinkedElement
                                            name={"Uniprot"}
                                            id={rowData.uniprot}
                                            link={"https://www.uniprot.org/uniprot/"}
                                        />

                                        <LinkedElement
                                            name={"PubChem"}
                                            id={rowData.pubchem}
                                            link={"https://pubchem.ncbi.nlm.nih.gov/compound/"}
                                        />
                                        <LinkedElement
                                            name={"RCSB PDB"}
                                            id={rowData.pdb}
                                            link={"http://www.rcsb.org/structure/"}
                                        />
                                        <LinkedElement
                                            name={"Drugbank"}
                                            id={rowData.drugbank}
                                            link={"https://www.drugbank.ca/drugs/"}
                                        />
                                        <LinkedElement
                                            name={"ATC"}
                                            id={rowData.atc}
                                            link={"https://www.whocc.no/atc_ddd_index/?code="}
                                        />
                                    </Box>

                                )
                            }
                        }]}
                    />}
                    <span>&nbsp;&nbsp;</span>


                </Box>
            </Box>

        );
    }
}