import React from "react";


/**
 * @return {null}
 */
export default function Element(props) {
    const name = props.name;
    let id;
    id = props.id;


    if (id != null && id !=="") {
        return (
            <div>
            <h2>{name}:</h2>
            <h3> {id}</h3>
            </div>
        );
    }
    return null;
}