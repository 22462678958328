import React, {Component} from "react";
import {Box, Heading} from "grommet";
import {DetailTable} from "../../content";
import {LoadingBar} from "../../../LoadingBar";


export class InteractionTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            intData: [],
            loading: true,
        };
    }

    componentDidMount() {
        const {url} = this.props;
        fetch(url)
            .then(response => response.json())
            .then(intData => this.setState({intData: intData["interactions"],
                loading: false,
            }));

    }


    render() {
        const {intData,loading} = this.state;
        const {heading} = this.props;

        return (

            <Box fill align="start" justify="center" background={"#fafafa"} pad="small">
                <Box fill align="start" justify="center">
                    <Heading
                        color={"#76B7B2"} level={2}>
                        {heading}
                    </Heading>

                </Box>
                <Box fill align="center" justify="center">
                    {loading ?      <LoadingBar/> :
                    <DetailTable


                        data={intData}


                        columns={
                            [
                                {title: 'Drug', field: 'drug', defaultSort: "asc"},
                                {title: 'Target', field: 'target'},
                                {title: 'Target Species', field: 'target_specie'},
                                {title: 'Target Synonyms', field: 'target_synonyme'},
                                {title: 'Target Gene', field: "target_gene"},
                                {title: 'Type', field: "type"},
                                {title: 'Result', field: "result"},
                            ]
                        }
                    />}
                    <span>&nbsp;&nbsp;</span>


                </Box>
            </Box>

        );
    }
}