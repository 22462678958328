import { grommet } from "grommet";
import { deepMerge } from "grommet/utils";

export const theme = deepMerge(grommet, {

    "global": {
        "size": {
            //sidebar: "200px"
        },

        "colors": {
            "brand":"#76B7B2",
            "border": {
                "dark": "#ebebeb",
                "light": "#444444"
            },
            "background": "#fafafa",
            "placeholder": "#ebebeb",
        },


    },




});
