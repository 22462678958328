import React, {Component} from "react";
import {Box, Heading} from "grommet";
import "./Details.css"
import LinkedElement from "./LinkedElement";





export class ResearchDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pubData: [],
        };
    }

    componentDidMount() {
        const {url} = this.props;
        fetch(url)
            .then(response => response.json())
            .then(pubData => this.setState({pubData: pubData}));

    }


    render() {
        const {heading} = this.props;
        const {doi} = this.props;
        const {pubData} = this.state;



        return (

            <Box fill align="start" justify="center" background={"#fafafa"} pad="small">
                <Heading
                    color={"#76B7B2"} level={2}>
                    {heading}</Heading>

                <h2>
                    {pubData.author}
                </h2>


                <LinkedElement
                    name={"doi"}
                    id={doi}
                    link={"https://doi.org/"}
                />

                <h2>
                    Abstract
                </h2>
                {pubData.abstract}
                <span>&nbsp;&nbsp;</span>



            </Box>

        );
    }
}
