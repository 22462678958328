import React from "react";
import { Box, Paragraph,Text } from "grommet";
import corona2 from "../images/corona3.svg"
import './NotFound.css';


export const NotFound = () => (
  <Box fill align="center" pad={{ top: "large", horizontal: "small" }}>
      <Box style={{ flexDirection: 'row', justifyContent:'space-between' }}>
        {/*  <Text style={{fontSize: 100,lineHeight: 50, fontWeight: "bold", color:"#76B7B2" }}>
           C
          </Text>

      <img src={ corona2} className="App-logo" alt= "" />
          <Text style={{fontSize: 100, lineHeight: 50, fontWeight: "bold", color:"#76B7B2"}}>
              RDITE
          </Text>
*/}
          <Text style={{fontSize: 100,lineHeight: -10, fontWeight: "bold", color:"#76B7B2" }}>
              C
          </Text>

          <img src={ corona2} className="App-logo" alt= "" />
          <Text style={{fontSize: 100, lineHeight: -10, fontWeight: "bold", color:"#76B7B2"}}>
              RDITE
          </Text>


  </Box>


    <Paragraph textAlign="center" color="dark-4">
        The page you requested does not exist or has been removed.
    </Paragraph>
  </Box>
);
