import React from "react";
import PropTypes from "prop-types";
import {withRouter, matchPath} from "react-router";
import Divider from "@material-ui/core/Divider";
import {withStyles,} from '@material-ui/core/styles';
import {Button} from "@material-ui/core";


const SmallButton = withStyles({
    root: {
        textTransform: 'none',
        fontSize: 16,
        align:'center',
        alignItems:'center',
        color:"#3d3d3d",

        //textAlign:'left',
        paddingLeft:'20px',
        padding: '20px 20px',
        //display: "inline-block",
        // border: '1px solid',
        lineHeight: 1.5,
        outline: "none",
        //justifyContent: "left",
        '&:hover': {
            backgroundColor: "rgba(118,183,178,0.5)",
            borderColor: "#76B7B2",
            boxShadow: 'none',
        },
        '&:label': {
            Color: "rgba(118,183,178,0.5)",
            borderColor: "#76B7B2",
            boxShadow: 'none',
        },
        '&:wrapper': {
            paddingLeft:'0px',
            alignItems: 'center',
        },

        '&:active': {
            boxShadow: 'none',
            color: "#76B7B2",
            borderColor: "#76B7B2",
            backgroundColor: "#E3FDEE",


        },
        '&:focus': {
            color: "#76B7B2",
            outlineColor: "#76B7B2",
            backgroundColor: "#E3FDEE",

        },
    },
})(Button);

class RoutedButton extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,

    };


    onClick = (event) => {
        //this.updateReview();
        const {history, path,} = this.props;
        event.preventDefault();
        history.push(path);




    };


    static contextTypes = {
        router: PropTypes.object
    };
    render() {


        const {
            active,
            exact,
            location,
            history,
            path,
            strict,
            items,
            staticContext,
            id,
            onClick,
            Icon,
            ...rest
        } = this.props;
        // const pathMatch = matchPath(location.pathname, {exact, path, strict});

        const {router} = this.context;

        return (

            <>
                <div >
                    <Divider style={{margin: "0px 0"}}/>

                    <SmallButton
                        //size="large"
                        className="smallsidebar-item"
                        //active={active && !!pathMatch}
                        {...rest}
                        onClick={this.onClick}
                        id={id}
                        key={id}
                        active={
                            active ||
                            (router && !!matchPath(router.location.pathname, {path}))
                        }

                        path={path}
                        //label={label}


                        {...rest}


                    >

                      <Icon/>

                    </SmallButton>
                    <Divider style={{margin: "0px 0"}}/>
                </div>
            </>

        );
    }
}

export default withRouter(RoutedButton);

