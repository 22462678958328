import React, {Component, forwardRef} from "react";
import {
    ArrowUpward,
    ChevronLeft,
    ChevronRight,
    Clear,
    FilterList,
    FirstPage,
    LastPage,
    SaveAlt,
    Search,
} from "@material-ui/icons";
import MaterialTable from "material-table";


const tableIcons = {
    "Clear": forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    "DetailPanel": forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    "Export": forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    "Filter": forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    "FirstPage": forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    "LastPage": forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    "NextPage": forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    "PreviousPage": forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    "ResetSearch": forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    "Search": forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    "SortArrow": forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
};


export class Table extends Component {



    render() {


        const {columns, data, detailPanel,onRowClick} = this.props;

        return <><MaterialTable
            data={data}
            columns={columns}
            icons={tableIcons}
            title=""
            onRowClick={onRowClick}
            detailPanel={detailPanel}
            toolbar={false}
            options={{

                pageSizeOptions: [20, 50, 100],
                pageSize: 20,
                //filtering: true,

                exportButton: true,

            }}
        /></>;
    }

}


