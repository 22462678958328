import React, {Component} from "react";
import {Box, Heading} from "grommet";
import {GroupableTable, Table} from "./content";
import {Publication, Target} from "../icons";
import {MoleculeDetails} from "./details/description/";
import {InteractionTable, PublicationsTable} from "./details/tables/";
//import {LoadingBar} from "../LoadingBar";

//const url = require("../data/url");
export class Drugs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            //loading:true
        };
    }

    //state = { loading: true };

/*    componentDidMount() {

        fetch(url.drugs)
            .then(response => response.json())
            .then(data => this.setState({data:data.data,
                loading:false
            })).catch(error => console.log(`Error in promises ${error}`));

    }*/





    render() {
       let url = require(".././data/url");
        const {data} = this.props;
        //const {data,loading} = this.state;

        return (

            <Box fill align="start" justify="center">
                <Heading>Drugs</Heading>



                <GroupableTable

                    data={data}

                    columns={
                        [
                            {title: 'Name', field: 'name',defaultSort:"asc"},
                            {title: 'Synonyms', field: "syn"},

                            {title: 'References* \n computational ', field: "ref_computational"},
                            {title: 'References* \n in vitro ', field: "ref_in_vitro"},
                            {title: 'References* \n  in vivo ', field: "ref_invivo"},
                            {title: 'References* \n  clinical trails ', field: "ref_clinical"}


                            /* {title: 'PubChem', field: 'pubchem'},
                             {title: 'RCSB PDB', field: 'pdb'},
                             {title: 'DrugBank', field: 'drugbank'},
                             {title: 'ATC', field: 'atc'},*/
                        ]
                    }

                    detailPanel={[
                        {

                            tooltip: 'Details',
                            render: rowData => {


                                return (
                                    <MoleculeDetails heading={rowData.name}
                                                     pubchem={rowData.pubchem}
                                                     pdb={rowData.pdb}
                                                     drugbank={rowData.drugbank}
                                                     atc={rowData.atc}
                                                     uniprot={rowData.uniprot}
                                                     id={rowData.id}
                                                     //syn={rowData.syn}
                                                     //gene={rowData.gene}
                                                     smile={rowData.smile}
                                                     description={rowData.description}
                                                     species={rowData.species}
                                                     url={url.drugDetails + rowData.id}
                                    />

                                )
                            }
                        },
                        {
                            icon: Target,
                            tooltip: 'Targets',

                            render: rowData => {

                                return (

                                    <InteractionTable heading={rowData.name+": Related Targets"}
                                                      url={url.drugDetails + rowData.id}

                                    />
                                )
                            }
                        },

                        {
                            icon: Publication,
                            tooltip: 'Publications',
                            render: rowData => {
                                return (
                                    <PublicationsTable heading={rowData.name}
                                                       url={url.drugDetails + rowData.id}

                                    />
                                )
                            }
                        },

                    ]}


                />
                <span>&nbsp;&nbsp;</span>
                *Only peer-reviewed research articles were counted
                <span>&nbsp;&nbsp;</span>
            </Box>


        );
    }
}
