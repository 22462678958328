import React, {Component} from "react";
import {Box} from 'grommet';
import {GroupableTable} from "../../content";
import {ResearchDetails} from "./index";
import {Interaction} from "../../../icons";
import {InteractionTable} from "../tables";
import {FaVial} from 'react-icons/fa/index.esm';
import {MoleculesTable} from "../tables/MoleculesTable";


export class Researches extends Component {

    render() {

        let url = require("../../../data/url");
        const {data} = this.props;

        return (

            <Box fill align="start" justify="center"  animation={"slideLeft"}>
                 <GroupableTable
                    columns={
                        [
                            {title: 'Name', field: 'name', defaultSort: "asc"},
                            {title: 'Authors', field: "author"},
                            {title: 'Source', field: 'database'},
                            /* {title: 'DOI', field: 'doi'},

                             {title: 'Curated', field: 'curated', lookup: {1: 'yes'}},*/
                            {title: 'Date', field: 'created'},
                        ]
                    }

                    detailPanel={[
                        {

                            //icon :FaCapsules,
                            tooltip: 'Details',
                            render: rowData => {


                                return (
                                    <ResearchDetails heading={rowData.name}
                                                     url={url.pubDetails + rowData.id}
                                                     doi={rowData.doi}
                                    />

                                )
                            }
                        },
                        {

                            //icon :FaCapsules,
                            tooltip: 'Molecules',
                            icon: FaVial,
                            render: rowData => {


                                return (
                                    <MoleculesTable heading={" Related Molecules"}
                                                    url={url.pubDetails + rowData.id}
                                    />

                                )
                            }
                        },
                        {
                            icon: Interaction,
                            tooltip: 'Interactions',

                            render: rowData => {


                                return (

                                    <InteractionTable heading={"Related Interactions"}
                                                      url={url.pubDetails + rowData.id}

                                    />
                                )
                            }
                        },

                    ]}


                    data={data}
                />


            </Box>


        );
    }
}