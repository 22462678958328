import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import {Box} from "grommet";
import RoutedTab from "./RoutedTab";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {FaAngleRight, FaAngleLeft} from 'react-icons/fa/index.esm';
import Button from "@material-ui/core/Button";
import RoutedNav from "./RoutedNav";
import Divider from "@material-ui/core/Divider";


const theme = createMuiTheme({
    palette: {
        primary: {main: "#76B7B2",},
        secondary: {main: "#76B7B2",},
    },
    typography: {useNextVariants: true},
});


export default function Sidebar(props) {

    const [value, setValue] = React.useState(1);
    const [expanded, setExpanded] = React.useState(true);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };


    const routedNav = props.items.map(({icon, pathto, value}) => (
        <RoutedNav
            key={value}
            Icon={icon}
            path={pathto}
        />));

    const routedTab = props.items.map(({icon, name, pathto, value}) => (
        <RoutedTab
            key={value}
            value={value}
            Symbol={icon}
            path={pathto}
            label={name}
        />));


    const Expanded = () => (
        <MuiThemeProvider theme={theme}>
            <div>
                <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary">
                    {routedTab}
                </Tabs>
            </div>
        </MuiThemeProvider>
    );

    const Collapsed = () => (

        <MuiThemeProvider theme={theme}>
            <div>
                <Box orientation="vertical"
                     onChange={handleChange}>
                    {routedNav}
                </Box>
            </div>
        </MuiThemeProvider>


    );


    if (expanded) {
        return <Box
            fill="vertical"
            width="250px"
            //background="white"
            elevation="medium" overflow="auto" animation="slideLeft">

            <Expanded/>
            <Box>
                <Button onClick={handleExpandClick} style={{padding: 20}}>
                    <span>&nbsp;&nbsp;</span>


                    <Box style={{flex: 1, flexDirection: 'row-reverse'}}>
                        <FaAngleLeft size={24}/>
                    </Box>
                    <span>&nbsp;&nbsp;</span>
                </Button>
            </Box>


        </Box>;
    }
    return <Box elevation="medium" fill="vertical" overflow="auto" animation="slideRight">

        <Collapsed/>


        <Button onClick={handleExpandClick} style={{paddingTop: 20, paddingBottom: 20}}>
            <span>&nbsp;&nbsp;</span>


            <Box style={{flex: 1, flexDirection: 'row-reverse'}}>
                <FaAngleRight size={24}/>
            </Box>
            <span>&nbsp;&nbsp;</span>
        </Button>
        <Divider/>

    </Box>;
}



