import React from "react";
//import {  Text  } from 'react-native';
import {Publication, Drug, Target, Interaction, ClinicalTrial} from "../icons";
import {Box, Heading, Text} from "grommet";


const size = 20;
const brand = "#76B7B2";


export const Access = () => (
    <Box fill align="start">
        <Heading>API</Heading>

        <Text style={{fontSize: size}}>
            There is an open API for access programmatically to the database. The API will print a JSON
            output:{"\n"}{"\n"}

            <Box className="List" style={{fontSize: size, paddingTop: 20, paddingLeft: 80, paddingBottom: 20}}>
                <Text style={{fontSize: size, color: brand}}> <a
                    href="https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=interaction"
                    rel="noopener noreferrer" target="_blank"><Interaction/> Interactions</a>
                </Text>

                <Text className={"ListItem"} style={{paddingBottom: 20}}>
                    https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=interaction{"\n"}{"\n"}
                </Text>

                <Text style={{fontSize: size, color: brand}}> <a
                    href="https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=target"
                    rel="noopener noreferrer" target="_blank"><Target/> Targets</a>
                </Text>

                <Text className={"ListItem"} style={{paddingBottom: 20}}>
                    https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=target{"\n"}{"\n"}
                </Text>

                <Text style={{fontSize: size, color: brand}}> <a
                    href="https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=drug"
                    rel="noopener noreferrer" target="_blank"><Drug/> Drugs</a>
                </Text>

                <Text className={"ListItem"} style={{paddingBottom: 20}}>
                    {"\t"} {"\t"}https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=drug{"\n"}{"\n"}
                </Text>
                <Text style={{fontSize: size, color: brand}}> <a
                    href="https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=publication"
                    rel="noopener noreferrer" target="_blank"><Publication/> Publications</a>
                </Text>
                <Text className={"ListItem"} style={{paddingBottom: 20}}>
                    {"\t"} {"\t"}https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=publication{"\n"}{"\n"}
                </Text>

                <Text style={{fontSize: size, color: brand}}> <a
                    href="https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=clinical_trial"
                    rel="noopener noreferrer" target="_blank"><ClinicalTrial/> Clinical Trials</a>
                </Text>
                <Text className={"ListItem"} style={{paddingBottom: 20}}>
                    {"\t"} {"\t"}https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=clinical_trial{"\n"}{"\n"}
                    {"\n"}
                </Text>
            </Box>
        </Text>


        {/* <Text style={{fontSize: size}} >
                There is an open API for access programmatically to the database. The API will print a JSON output:{"\n"}{"\n"}


                <Text style={{fontSize:size, color: brand   }}>{"\t"}  <a href="https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=interaction" rel="noopener noreferrer" target="_blank"><Interaction/> Interactions</a>
                    {"\n"} </Text>

                {"\t"} {"\t"}https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=interaction{"\n"}{"\n"}


                <Text style={{fontSize:size, color: brand   }}>{"\t"}  <a href="https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=target" rel="noopener noreferrer" target="_blank"><Target/> Targets</a>
                    {"\n"} </Text>

                {"\t"} {"\t"}https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=target{"\n"}{"\n"}

                <Text style={{fontSize:size, color: brand   }}>{"\t"}  <a href="https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=drug" rel="noopener noreferrer" target="_blank"><Drug/> Drugs</a>
                    {"\n"} </Text>

                {"\t"} {"\t"}https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=drug{"\n"}{"\n"}

                <Text style={{fontSize:size, color: brand   }}>{"\t"}  <a href="https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=publication" rel="noopener noreferrer" target="_blank"><Publication/> Publications</a>
                    {"\n"} </Text>

                {"\t"} {"\t"}https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=publication{"\n"}{"\n"}


                <Text style={{fontSize:size, color: brand   }}>{"\t"}  <a href="https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=clinical_trial" rel="noopener noreferrer" target="_blank"><ClinicalTrail/> Clinical Trails</a>
                    {"\n"} </Text>

                {"\t"} {"\t"}https://corona.mathematik.uni-marburg.de/backend/api.php?action=list&table=clinical_trial{"\n"}{"\n"}
                {"\n"}
            </Text>*/}

    </Box>

);
