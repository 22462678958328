import React from "react";
import {Box, Heading} from "grommet";

//ToDo text


export const Disclosure = () => (
    <Box fill align="start" pad={{top: "large", horizontal: "small"}}>
        <Heading>Disclosure</Heading>


        <h2>Dienstanbieter - Service Provider</h2>

        <p>CORDITE</p>


        <p>Philipps-Universität Marburg<br/>
        Biegenstraße 10<br/>
       35037 Marburg<br/>
        Tel. 06421 28-20<br/>
        Fax 06421 28-22500<br/>


        FB 12 | Mathematik und Informatik<br/>
        Hans-Meerwein-Straße 6<br/>
        35043 Marburg</p>


        <h2> Umsetzung - Implementation</h2>

        CORDITE, vertreten durch die Philipps-Universität Marburg

        <h2>Nutzungsrechte - Right of use</h2>

        CORDITE, vertreten durch die Philipps-Universität Marburg

        Alle Rechte vorbehalten.
        Die Urheberrechte dieser Webseite unterliegen vollständig der Philipps-Universität Marburg

        <h2>Haftung für Inhalte - Liability for content</h2>

        Wir sind stetig bemüht, dass die Inhalte der Website aktuell, vollständig und richtig angeboten werden. Jedoch
        kann nicht vollständig ausgeschlossen werden, dass Fehler auftreten können. Als Dienstanbieter sind wir gemäß
        den Bestimmungen §7 Abs. 1 TMG für die Inhalte der Seiten nach der allgemeinen Gesetzesgrundlage verantwortlich.
        Jedoch sind wir gemäß der §§ 8 - 10 TMG nicht dazu verpflichtet, übermittelte oder gespeicherte fremde
        Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        Verpflichtungen zur Entfernung oder Sperrung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
        unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnisnahme einer konkreten
        Rechtsverletzung möglich. Bei Bekanntwerden dieser werden wir diese Inhalte umgehend entfernen.
        <span>&nbsp;&nbsp;</span>
        We make every effort to ensure that the content of this website is up-to-date, complete and correct. However, it
        cannot be completely excluded that errors may occur. As a service provider, we are responsible for the content
        of the pages following the provisions of §7 Para. 1 TMG (German Telemedia Act) and the general legal basis.
        However, following §§ 8 - 10 TMG we are not obliged to monitor transmitted or stored third-party information or
        to investigate circumstances that indicate illegal activity. Obligations to remove or block information
        following general laws remain unaffected by this. However, liability in this respect is only possible from the
        point in time at which we become aware of a concrete violation of the law. Upon becoming aware of this, we will
        remove this content immediately.

        <h2>Haftung für Links - Liability for links </h2>

        Das Angebot dieser Website enthält Links zu externen Webseiten von Dritten, auf deren Inhalte wir keinen
        Einfluss haben. Deswegen können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
        verlinkten Webseiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Eine Überprüfung
        auf mögliche Rechtsverstöße wurde zum Zeitpunkt der Verlinkung unternommen. Es waren zu diesem genannten
        Zeitraum keine rechtswidrigen Inhalte erkennbar. Eine dauerhafte inhaltliche Kontrolle der verlinkten Seiten
        ohne eine Annahme zu einer Rechtsverletzung ist nicht zumutbar. Sollten Rechtsverletzungen bekannt werden,
        werden die entsprechenden Links umgehend entfernt.
        <span>&nbsp;&nbsp;</span>
        The content of this website contains links to external websites of third parties over whose content we have no
        influence. Therefore, we cannot assume any liability for these external contents. The respective provider or
        operator of these pages is always responsible for the contents of the linked websites. A check for possible
        violations of the law was undertaken at the time of linking. No illegal contents were recognizable at this time.
        A permanent control of the contents of the linked pages without an assumption of a violation of law is not
        reasonable. Should violations of the law become known, the corresponding links will be removed immediately.

        <h2> Urheberrecht - Copyright</h2>

        Die erstellten Inhalte und Werke dieser Website unterliegen dem deutschen Urheberrecht. Eine Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der
        schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für
        den privaten, nicht aber für den kommerziellen Gebrauch gestattet. Sofern vorhanden, werden Inhalte von
        Drittanbietern als diese gekennzeichnet. Sollten Sie dennoch auf eine Verletzung des Urheberrechts aufmerksam
        werden, bitten wir um den entsprechenden Hinweis. Bei bekannt werden von Rechtsverletzungen werden die
        entsprechenden Inhalte umgehend entfernt.
        <span>&nbsp;&nbsp;</span>
        The contents and works created on this website are subject to German copyright law. Any duplication, processing,
        distribution or any form of commercialization of such material beyond the scope of the copyright law shall
        require the prior written consent of its respective author or creator. Downloads and copies of these pages are
        only permitted for private, but not for commercial use. If available, the contents of third parties are marked
        as such. Should you nevertheless become aware of a violation of copyright, please notify us accordingly. If
        violations become known, the corresponding content will be removed immediately.

        <h2>Datenschutz - Privacy</h2>

        Soweit personenbezogene Daten (beispielsweise E-Mail-Adressen) erhoben werden, erfolgt dies soweit wie möglich
        stets auf freiwilliger Basis. Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten nur dann
        weiter, wenn dies im gesetzlichen Rahm erlaubt ist. Die Website können Sie auch besuchen, ohne Angaben zu Ihrer
        Person zu machen. Die Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte für
        die Übersendung von nicht ausdrücklich angeforderten Informationsmaterialien sowie Werbung wird ausdrücklich
        widersprochen. Die Betreiber dieser Website behalten sich ausdrücklich rechtliche Schritt im Falle der
        unverlangten Zusendung von Werbeinformationen, etwa durch Spammails, vor.
        <span>&nbsp;&nbsp;</span>
        Insofar as personal data (e.g. e-mail addresses) is collected, this is always done as far as possible
        voluntarily. The website operator collects, uses and passes on your data only if this is permitted by law. You
        can also visit the website without providing any personal information. The use of contact data published within
        the framework of the imprint obligation by third parties for the transmission of information material and
        advertising not expressly requested is expressly prohibited. The operators of this website expressly reserve the
        right to take legal action against unsolicited mailing or e-mailing of spam and other similar advertising
        materials.

        <h2>Cookies</h2>

        Diese Webseite verwendet ausschließlich sogenannte First Party-Cookies (oder auch "Session-Cookies").
        Konkret werden Cookies für den folgenden Zweck verwendet: ein Login-/Session-Cookie um die Sitzung eines
        eingeloggten Benutzers aufrecht zu erhalten und das präferierte Theme (z.B. Dark-Mode) der Seite zu speichern.
        Dabei wird dieser Cookie erst gesetzt sofern der User sich einloggt und so eine Nutzer-spezifische Sitzung
        startet (Login).
        Durch ausloggen oder löschen des Accounts durch den Nutzers wird der Cookie wieder entfernt.
        <span>&nbsp;&nbsp;</span>
        This website uses only first-party cookies (or "session cookies").
        Specifically, cookies are used for the following purposes only: a login cookie to maintain the session of a
        logged-in user which also stores the preferred theme (e.g. dark mode) of the site. This cookie is set only if
        the user logs in and thus starts a user-specific session.
        By logging out or deleting the account through the user, the cookie will be removed again.
        <span>&nbsp;&nbsp;</span>


    </Box>
);