import {Box, Grid,Text} from "grommet";
import Button from "@material-ui/core/Button";
import logo from "./images/logo-cordite-alpha.png";
import heider from "./images/Logo_HeiderLab-alpha.png";
import React from "react";
import "./Header.css"


export  const Header =()=>(
<Grid fill rows={["auto", "flex", "auto"]}>
    <Box tag="header" background={"white"} pad="xsmall">
            <Box style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                <Box>
                    <Button  href="/" color="primary">
                        <img className={"logo"} src={logo} alt=""/>


                    </Button>
                </Box>
                <Box>
                    <Text className="header" > CORona Drug
                        InTEractions database </Text>
                </Box>
                <Box>
                    <Button  href="http://heiderlab.de/" rel="noopener noreferrer" target="_blank" color="primary">
                        <img className={"logo"} src={heider}  alt=""/>
                    </Button>
                </Box>
            </Box>
    </Box>
</Grid>

);