import React, {Component} from "react";
import {GroupableTable} from "./content/GroupableTable"
import {Box, Heading} from "grommet";
import {Publication, Drug} from "../icons";
import {MoleculeDetails} from "./details/description";
import {PublicationsTable, InteractionTable} from "./details/tables";


export class Targets extends Component {
    render() {

        const {data} = this.props;
        let url = require(".././data/url");

        return (


            <Box fill align="start" justify="center">
                <Heading>Targets</Heading>


                <GroupableTable


                    columns={
                        [
                            {title: 'Name', field: 'name', defaultSort: "asc"},
                            {title: 'Synonym', field: "syn"},
                            {title: 'Genes', field: 'gene'},
                            {title: 'Origin', field: 'origin'},
                            /*   {title: 'Value', field: 'value', lookup: {1: 'in vitro'}},
                            {title: 'ID', field: 'id'},*/
                        ]
                    }

                    detailPanel={[
                        {
                            //icon :FaCapsules,
                            tooltip: 'Details',
                            render: rowData => {
                                return (
                                    <MoleculeDetails heading={rowData.name}
                                                     pubchem={rowData.pubchem}
                                                     pdb={rowData.pdb}
                                                     drugbank={rowData.drugbank}
                                                     atc={rowData.atc}
                                                     id={rowData.id}
                                        //syn={rowData.syn}
                                                     url={url.drugDetails + rowData.id}
                                    />
                                )
                            }
                        },
                        {
                            icon: Drug,
                            tooltip: 'Drugs',

                            render: rowData => {

                                return (

                                    <InteractionTable heading={rowData.name + ": Related Drugs"}
                                                      url={url.drugDetails + rowData.id}
                                    />
                                )
                            }
                        },
                        {
                            icon: Publication,
                            tooltip: 'Publications',
                            render: rowData => {
                                return (
                                    <PublicationsTable heading={rowData.name}
                                                       url={url.drugDetails + rowData.id}
                                    />
                                )
                            }
                        },

                    ]}

                    data={data}
                />


            </Box>
        );
    }
}
