import React, {Component} from "react";
import {GroupableTable} from "./content/GroupableTable"
import {Box, Heading} from "grommet";
import {Publication} from "../icons";
import {PublicationsTable} from "./details/tables";


export class Interactions extends Component {

    render() {
        let url = require(".././data/url");
        const {data} = this.props;


        return (


            <Box fill align="start" justify="center">
                <Heading>Interactions</Heading>

                <GroupableTable
                    onRowClick={(event, rowData, togglePanel) => togglePanel()}
                    data={data}

                    columns={
                        [
                            {title: 'Target', field: 'target', defaultSort: "asc"},
                            {title: 'Target affiliation', field: "target_specie"},
                            {title: 'Drug', field: 'drug'},
                            {title: 'Type', field: 'type'},
                            {title: 'Result', field: 'result'},

                        ]
                    }
                    detailPanel={[
                        /*        {
                                    //icon :FaCapsules,
                                    tooltip: 'Details',
                                    render: rowData => {
                                        return (
                                            <InteractionDetails
                                                heading={rowData.target +"-"+ rowData["drug"]}
                                                drug={rowData["drug"]}
                                                target={rowData["target"]}
                                                targetsyn={rowData["target_synonyme"]}
                                                targetspe={rowData["target_specie"]}
                                                drugsyn={rowData["drug_synonyme"]}
                                                drugspe={rowData["drug_specie"]}
                                                type={rowData["type"]}
                                                result={rowData["result"]}
                                            />
                                        )
                                    }
                                },*/

                        {
                            icon: Publication,
                            //openIcon: <DescriptionIcon color={"#76B7B2"}/>,
                            tooltip: 'Publications',
                            render: rowData => {
                                return (
                                    <PublicationsTable heading={rowData.target + "-" + rowData["drug"]}
                                                       url={url.interDetails + rowData.id}
                                    />

                                )
                            }
                        },

                    ]}


                />


            </Box>
        );
    }
}
